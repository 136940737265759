/* eslint-disable import/no-unused-modules */
import React, { useState } from 'react';
import styled from 'styled-components';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import FaqAccordion from '../components/FaqAccordion';
import Helmet from '../components/Helmet';
import TabPanel from '../components/TabPanel';
import { H2 } from '../components/Text';
import { PageLayout } from '../shared/layouts';
import withI18n from '../hocs/withI18n';
import { faqTabs } from '../modules/faqs/Faqs.helper';
import { FONT_BOLD, FONT_MEDIUM, PRIMARY_COLOR } from '../shared/styles';

export const Head = withI18n(() => {
  return <Helmet pageName="faqs" />;
});

const Faqs = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (_, newValue) => setActiveTab(newValue);

  return (
    <PageLayout>
      <H2>{t('faq.title')}</H2>

      <FaqTabs
        value={activeTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons={false}
        aria-label=""
      >
        {faqTabs.map((eachTab, i) => (
          <Tab key={i} label={t(eachTab.title)} />
        ))}
      </FaqTabs>

      {faqTabs.map((eachTab, i) => (
        <FaqTabpanel
          key={i}
          value={activeTab}
          index={i}
          // dir={language?.dir}
        >
          <FaqAccordion hideTitle faqList={eachTab.faqList} />
        </FaqTabpanel>
      ))}
    </PageLayout>
  );
};

const FaqTabs = styled(Tabs)`
  .MuiTab-textColorPrimary.Mui-selected {
    color: #000000;
    ${FONT_BOLD};
  }
  .MuiTabs-indicator {
    background-color: ${PRIMARY_COLOR};
  }
  .MuiTab-root {
    color: grey;
    font-size: 17px;
    min-width: 160px;
    text-transform: unset !important;
    ${FONT_MEDIUM}
  }
`;
const FaqTabpanel = styled(TabPanel)`
  margin-top: -2rem;
`;

export default Faqs;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
